import React from "react";
import { Routes, Route } from "react-router-dom";
import "./assets/App.css";
import LoginScreen from "./pages/LoginScreen.js";
import CreateCode from "./pages/CreateCode";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="/create-code" element={<CreateCode />} />
    </Routes>
  );
};
export default App;
