import React, { useEffect, useRef, useState } from "react";
import { BsDiscord, BsTwitch } from "react-icons/bs";
import { AiOutlineTwitter, AiFillYoutube } from "react-icons/ai";

const CreateCode = () => {
  const circleRef = useRef(0);
  let speedX = 5; // X eksenindeki hareket hızı
  let speedY = 3; // Y eksenindeki hareket hızı

  useEffect(() => {
    const circle = circleRef.current;

    const moveCircle = () => {
      const windowHeight = window.innerHeight - 100;
      const windowWidth = window.innerWidth - 100;
      const currentTop = parseInt(circle.style.top) || 0;
      const currentLeft = parseInt(circle.style.left) || 0;

      const newTop = currentTop + speedY;
      const newLeft = currentLeft + speedX;

      // Ekrana çarpma kontrolü
      if (newTop <= 0 || newTop >= windowHeight) {
        speedY = -speedY; // Y ekseninde yönü tersine çevir
      }

      if (newLeft <= 0 || newLeft >= windowWidth) {
        speedX = -speedX; // X ekseninde yönü tersine çevir
      }

      // Yeni pozisyonları uygula
      circle.style.top = newTop + "px";
      circle.style.left = newLeft + "px";

      // Hareketi devam ettir
      requestAnimationFrame(moveCircle);
    };
    moveCircle();
  }, []);

  return (
    <div class="container">
      <div class="background">
        <div class="gunes"></div>
        <div class="circle" ref={circleRef}>
          {" "}
          <svg
            viewBox="0 0 500 500"
            xmlns="http://www.w3.org/2000/svg"
            //   xmlns:xlink="http://www.w3.org/1999/xlink"
            width="100%"
            id="blobSvg"
          >
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop
                  offset="0%"
                  style={{ stopColor: "rgb(42, 21, 163)" }}
                ></stop>
                <stop
                  offset="100%"
                  style={{ stopColor: "rgb(198, 27, 254)" }}
                ></stop>
              </linearGradient>
            </defs>
            <path fill="url(#gradient)">
              <animate
                attributeName="d"
                dur="10000ms"
                repeatCount="indefinite"
                values="M444,327Q404,404,327,415Q250,426,163.5,424.5Q77,423,48.5,336.5Q20,250,55.5,170.5Q91,91,170.5,72Q250,53,319,82.5Q388,112,436,181Q484,250,444,327Z;
              M426.5,328.5Q407,407,328.5,433Q250,459,171,433.5Q92,408,72,329Q52,250,85.5,184.5Q119,119,184.5,59.5Q250,0,324,51Q398,102,422,176Q446,250,426.5,328.5Z;
              M417.5,312Q374,374,312,433.5Q250,493,164,457.5Q78,422,63,336Q48,250,68.5,169.5Q89,89,169.5,57Q250,25,331.5,56Q413,87,437,168.5Q461,250,417.5,312Z;
              M422,329Q408,408,329,428.5Q250,449,168,431.5Q86,414,71.5,332Q57,250,90,186.5Q123,123,186.5,63.5Q250,4,313.5,63.5Q377,123,406.5,186.5Q436,250,422,329Z;
              M429.5,324Q398,398,324,412.5Q250,427,170,418.5Q90,410,76,330Q62,250,74.5,168.5Q87,87,168.5,58.5Q250,30,318,72Q386,114,423.5,182Q461,250,429.5,324Z;
              M444,327Q404,404,327,415Q250,426,163.5,424.5Q77,423,48.5,336.5Q20,250,55.5,170.5Q91,91,170.5,72Q250,53,319,82.5Q388,112,436,181Q484,250,444,327Z"
              ></animate>
            </path>
          </svg>
        </div>
      </div>
      <div class="banner">
        <div class="leftBanner">
          <div class="bannerLogo">
            <img src="foto1.jpg" id="logo" />
          </div>
          <div class="bannerYazi">
            <div class="bannerYaziUst">QREATOR</div>
            <div class="bannerYaziAlt">QREATING MEANINGFUL CONNECTIONS</div>
          </div>
        </div>
        <div class="rightBanner">
          <div class="loginSign">
            <div class="loginYazi"> LOGIN</div>
            <div class="signYazi">SIGN UP</div>
          </div>
          <div className="icon">
            <BsDiscord size={27} />
            <AiOutlineTwitter size={27} />
            <BsTwitch size={27} />
            <AiFillYoutube size={27} />
          </div>
        </div>
      </div>
      <div class="bannerYazi">
        <div class="bannerYaziUst1">INPUT YOUR DESIRED LINK BELOW</div>
        <div class="textbox1">
          <input type="text" placeholder="" />
        </div>
      </div>
      <div class="bannerYazi">
        <div class="bannerYaziUst2">DESCRIBE YOUR DESIRED QR CODE</div>
        <ul class="container">
          <li>
            <textarea class="button-perspective" placeholder=""></textarea>
            <input type="text" class="button-perspective2" placeholder="" />
          </li>
        </ul>
        <div class="bannerYazi">
          <div class="bannerYaziUst3">
            <button class="buton">RECEIVE YOUR QREATION</button>
          </div>
        </div>
        <img src="foto1.jpg" id="altimg" />
      </div>
    </div>
  );
};

export default CreateCode;
